import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Heading, SignTouchPreview } from '../../components';
import { PreviewHome } from '../../components/SignTouchPreview/pages';
import { AnimatePresence } from 'framer-motion';
import { useYupResolver } from '../../hooks';
import { useForm } from 'react-hook-form';
import { developmentMetadataSchema } from './developmentMetadata.schema';
import { CoordinateSelect, Select, TextInput, SingleImage, MultiImage, Button } from '../../components/FormV2/components';
import { Box } from '@mui/material';
import './styles.scss';
import { metadataService } from '../../lib/signtouch';
import { setDevelopmentMetaData } from '../../store/actions/general';
import addPending from '../../utils/addPending';
import { addError, addNotification } from '../../utils';
import removePending from '../../utils/removePending';

const DevelopmentProperties = () => {
  const { currentDevelopment } = useSelector((state) => state.general);

  const resolver = useYupResolver(developmentMetadataSchema);
  const { control, handleSubmit } = useForm({
    resolver,
  });
  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);

  const onSubmit = async data => {
    setSaving(true);
    const savingToast = addPending('updating development metadata');

    try {
      await metadataService.updateMetadata(data);
      await dispatch(setDevelopmentMetaData());
      removePending(savingToast);
      addNotification('development metadata updated successfully');
    } catch {
      removePending(savingToast);
      addError('Error updating development metadata');
    }

    setSaving(false);
  };

  return (
    <>
      <div className='page-padding'>
        <div className='heading-with-button'>
          <Heading label='Development Metadata' />
        </div>
        <div className='parent-properties'>
          <div className='parent-properties-forms'>
          <form onSubmit={handleSubmit(onSubmit)} style={{ margin: '10px 0' }}>
            <Box
              sx={{
                maxWidth: '600px',
                p: 4,
                backgroundColor: 'white',
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2
              }}
            >
              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                <TextInput
                  name='name'
                  label='NAME'
                  control={control}
                  defaultValue={currentDevelopment?.name}
                  full
                />
              </Box>

              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: 2 }}>
                <CoordinateSelect
                  name='developmentLocation'
                  label='DEVELOPMENT LOCATION'
                  control={control}
                  defaultValue={currentDevelopment?.developmentLocation}
                  full
                />
              </Box>

              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                <Select
                  name='mapType'
                  label='Map Type'
                  control={control}
                  options={[
                    { label: 'Basic', value: 'beabloo' },
                    { label: 'MultiFloor', value: 'multi-floor' },
                    { label: '3d Map', value: 'three-map' }
                  ]}
                  defaultValue={currentDevelopment?.mapType}
                  full
                />
              </Box>
              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                <TextInput
                  name='mediaUrl'
                  label='Media URL'
                  control={control}
                  defaultValue={currentDevelopment?.mediaUrl}
                  full
                />
              </Box>

              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: 2 }}>
                <SingleImage
                  name='mapImage'
                  label='MAP IMAGE'
                  control={control}
                  defaultValue={currentDevelopment?.mapImage}
                  full
                />
              </Box>

              <Heading label='Local Information options' />
              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: 2 }}>
                <MultiImage
                  name='localInformationPoster'
                  label='LOCAL INFORMATION POSTERS'
                  control={control}
                  defaultValue={Array.isArray(currentDevelopment?.localInformationPoster)
                    ? currentDevelopment?.localInformationPoster || []
                    : [...(currentDevelopment?.localInformationPoster || [])]
                  }
                  full
                />
              </Box>

              <Heading label='Background Images' />
              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: 2 }}>
                <SingleImage
                  name='promotionsBackgroundImage'
                  label='PROMOTIONS BACKGROUND IMAGE'
                  control={control}
                  defaultValue={currentDevelopment?.promotionsBackgroundImage || null}
                  full
                />
              </Box>

              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: 2 }}>
                <Button type='submit' disabled={saving}>{saving ? 'Saving...' : 'Save'}</Button>
              </Box>

            </Box>
            </form>
          </div>

          <div style={{ position: 'fixed', right: '10vw' }}>
            <AnimatePresence>
              <SignTouchPreview properties={{}}>
                <PreviewHome />
              </SignTouchPreview>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </>
  );
};

export default DevelopmentProperties;
