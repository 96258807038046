import { useParams } from 'react-router';
import { Heading } from '../../components';
import { Link } from 'react-router-dom';
import { ReactComponent as PageIcon } from '../../assets/icons/document.svg';
import './styles.scss';

const StaticPagesList = () => {
  const { developmentId } = useParams();

  const staticPages = [
    {
      title: 'welcome',
      path: 'welcome',
    },
    {
      title: 'home',
      path: 'home',
    },
    {
      title: 'contact',
      path: 'contact',
    },
    {
      title: 'register interest',
      path: 'register-interest',
    },
    {
      title: 'mortgage calculator',
      path: 'mortgage-calculator',
    }
  ];

  return (
    <div className='page-padding static-pages'>
      <div className='heading-with-button'>
        <Heading label='static pages' />
      </div>

      <div className='static-pages-list'>
        <p>
          Static pages are the less complicated pages of Signtouch and only
          require a single configuration of content. You can do that here by
          clicking into any of the pages below.
        </p>

        {staticPages.map(({ title, path, icon }) => {
          return (
            <div
              className='static-page-link-container'
              key={`static-page-${title}`}
            >
              <Link to={`/developments/${developmentId}/pages/${path}`}>
                <div className='static-page-link'>
                  {icon ? icon : <PageIcon fill={'#1b1b1d'} />}
                  <p>{title}</p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StaticPagesList;
