import store from '../../store';
import { beablooApi } from '../../utils';

const getDashboardsByUsername = async params => {
  const { general: { currentDevelopment } } = store.getState();
  let url = `/developments/${currentDevelopment.id}/metabase`;
  if (params) {
    const checkForParamInUrl = url.includes('?');
    const countParams = Object.keys(params).length;
    if (countParams > 0) {
      url += checkForParamInUrl ? '&' : '?';
      Object.keys(params).forEach((key, index) => {
        url += `${key}=${params[key]}${index < countParams - 1 ? '&' : ''}`;
      });
    }
  }
  const response = await beablooApi({
    method: 'GET',
    route: url,
  }).then((r) => {
    return r;
  });

  if (!response.success) {
    throw new Error('unable to get dashboards by username')
  }

  return response.data;
}

const getDashboardByDashboardId = async dashboardId => {
  const { general: { currentDevelopment } } = store.getState();
  const response = await beablooApi({
    method: 'GET',
    route: `/developments/${currentDevelopment.id}/metabase/dashboard/${dashboardId}`,
  }).then((r) => {
    return r;
  });

  if (!response.success) {
    throw new Error('unable to get dashboard by dashboard id')
  }

  return response.data;
}

const metabaseService = {
  getDashboardsByUsername,
  getDashboardByDashboardId
}

export default metabaseService;
