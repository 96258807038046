/* eslint-disable eqeqeq */
import schema from '../schemas/_houseView';

const houseViewReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_HOUSE_VIEW':
      return [...action.data];

    case 'ADD_HOUSE_VIEW':
      return [...state, action.data];

    case 'UPDATE_HOUSE_VIEW':
      return [
        ...state.filter((house) => house.id != action.data.id),
        action.data,
      ];

    case 'DELETE_HOUSE_VIEW':
      return [...state.filter((house) => house.id != action.data.id)];

    default:
      return [...state];
  }
};

export default houseViewReducer;
 