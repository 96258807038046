import { Button, Heading, Page, SubHeading } from '../../components';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addError, addNotification, beablooApi } from '../../utils';
import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { MultiFloorImageSection } from './MultiFloorImageSection';
import { MultiFloorFloorsSection } from './FloorsSection';
import { setMultiFloor } from '../../store/actions/multiFloor';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { multiFloorService } from '../../lib/signtouch';
import houseViewServices from '../../lib/signtouch/houseViewService';
import { dummyImage } from './data';
import { addHouseView } from '../../store/actions/houseView';

export const MultiFloorHouseViewConfigrationPage = () => {
  const { developmentId, houseTypeId } = useParams();
  const [loading, setLoading] = useState(false);
  const [entryMade, setEntryMade] = useState(false); // New state to track entry completion
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [multiFloor, setMultiFloor] = useState(useSelector(state => state.houseView.filter(house => house.houseTypeId === parseInt(houseTypeId)))[0]);

  const makeNewEntry = async () => {
    setLoading(true);
    try {
      const res = await houseViewServices.createHouseView({ 
        houseTypeId: parseInt(houseTypeId),
        floors: [],
        developmentImage: dummyImage
      });
      dispatch(addHouseView(res));
      setMultiFloor(res);
      setEntryMade(true); // Set entry completion to true
    } catch (error) {
      console.error('Error creating new entry:', error);
    }
  };

  useEffect(() => {
    if (multiFloor === undefined) {
      makeNewEntry();
    } else {
      setEntryMade(true); // If multiFloor is already defined, set entry completion to true
    }
  }, [multiFloor, entryMade]);

  const houseTypes = useSelector(state => state.houseTypes.filter(houseType => houseType.id === parseInt(houseTypeId)));
  const [floors, setFloors] = useState(multiFloor?.floors ? multiFloor.floors.sort((a, b) => a.order - b.order) : []);

  const handleFloorsChange = values => {
    setFloors(values);
  };

  const handleCreateFloor = () => {
    navigate(`/developments/${developmentId}/house-view/multi-floor/configuration/${houseTypeId}/create-floor`);
  };

  return (
    <Page>
      <div className='heading-with-button'>
        <Heading label={`Floor Configuration - ${houseTypes[0].name}`} />
        {entryMade && <Button label={'create floor'} onClick={handleCreateFloor} />}
      </div>
      <SubHeading>
        Here you can manage the floors for your house type.
      </SubHeading>
      {!entryMade
        ? 'loading...' 
        : <Box sx={{ maxWidth: '1600px', width: '100%' }}>
          {(multiFloor) &&
            <Stack direction={'column'} alignItems='stretch' spacing={{ xs: 1, sm: 2 }}>
              {multiFloor.floors &&
                <MultiFloorFloorsSection 
                onChange={handleFloorsChange}
                houseTypeId={houseTypeId}
                />  
              }
            </Stack>
          }
          {multiFloor.floors.length === 0 &&
            <p>No Floor Created Yet</p>
          }
        </Box>
      }
    </Page>
  );
};
