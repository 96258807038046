import { useEffect, useState } from 'react';
import { Form, Heading, Page } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { addError, addNotification } from '../../utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { addMultiFloorFloor } from '../../store/actions/multiFloor';
import { multiFloorService } from '../../lib/signtouch';

export const CreateFloorPage = () => {
  const [saving, setSaving] = useState(false);
  const { developmentId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const multiFloor = useSelector(state => state.multiFloor);

  const handleSubmit = async data => {
    const payload = {
      floors: [
        ...multiFloor.floors,
        {
          ...data,
          order: multiFloor.floors.length + 1
        }
      ]
    }

    const pendingToast = addPending('Adding your floor...');
    setSaving(true);

    try {
      const response = await multiFloorService.updateMultiFloor(payload);
      const newFloor = response.floors.find(floor => floor.order === multiFloor.floors.length + 1);
      dispatch(addMultiFloorFloor(newFloor))
  
      addNotification('Floor was created successfully')
      navigate(`/developments/${developmentId}/multi-floor/configuration`);
      
    } catch {
      addError('unable to create floor');
    }

    setSaving(false);
    removePending(pendingToast);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const houseTypeId = searchParams.get('houseTypeId');
  }, [location.search]);
  return (
    <Page>
      <Heading label='create floor' />
      <Form 
        onSubmit={handleSubmit}
        canSubmit={!saving}
        inputs={[
          {
            name: 'name',
            type: 'text',
            label: 'name',
          },
          {
            name: 'image',
            label: 'Image',
            type: 'single-image',
          },
          {
            name: 'backgroundColor',
            label: 'Background Color',
            type: 'colour',
            initialValue: '#FFFFFF',
          }
        ]}
      />
    </Page>
  )
};