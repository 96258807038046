import store from '../../store';
import { beablooApi } from '../../utils';

const createHouseView = async payload => {
  const { general: { currentDevelopment } } = store.getState();

  const response = await beablooApi({
    method: 'POST',
    route: `/developments/${currentDevelopment.id}/houseView/create`,
    payload,
  });

  if (!response.success) {
    throw new Error('unable to create house view')
  }

  return response.data;
}

const getHouseView = async id => {
  const { general: { currentDevelopment } } = store.getState();

  const response = await beablooApi({
    method: 'GET',
    route: `/developments/${currentDevelopment.id}/houseView/${id}`,
  });

  if (!response.success) {
    throw new Error('unable to get house view')
  }

  return response.data;
};

const updateHouseView = async (payload, houseViewId) => {
  const { general: { currentDevelopment } } = store.getState();

  const response = await beablooApi({
    method: 'PUT',
    route: `/developments/${currentDevelopment.id}/houseView/${houseViewId}/update`,
    payload,
  });

  if (!response.success) {
    throw new Error('unable to update house view')
  }

  return response.data;
}

const deleteHouseView = async id => {
  const { general: { currentDevelopment } } = store.getState();

  const response = await beablooApi({
    method: 'DELETE',
    route: `/developments/${currentDevelopment.id}/houseView/${id}/delete`,
  });

  if (!response.success) {
    throw new Error('unable to delete house view')
  }

  return response.data;
};


const houseViewServices = {
  createHouseView,
  updateHouseView,
  deleteHouseView,
  getHouseView,
}

export default houseViewServices;
