const propertiesDefaultState = {
  name: 'Signtouch',
  branding: {
    // primary colour off the parent
    primary: '#dd1f64',
    // secondary colour off the parent
    secondary: '#35d38b',
    // tertiary colour off the parent
    tertiary: '#009ad1',
    // color to be used for card components used as
    // `background: linear-gradient([0], [1]);`
    // will always be an array of 2
    // if background is a single colour the background will still use linear-gradient
    card: {
      type: 'gradient',
      value: {
        angle: 45,
        colors: ['#121212', '#121212'],
      },
    },
    // colour to be used for header / footer / etc.. backgrounds
    // see notes for card on how to use
    section: {
      type: 'gradient',
      value: {
        angle: 45,
        colors: ['#121212', '#121212'],
      },
    },
    // colors for map
    map: {
      available: '#dd1f64',
      sold: '#35d38',
      reserved: '#009ad1',
      future: '#009ad1',
      futurelabel: '',
      future2: '#009ad1',
      future2label: '',
      future3: '#009ad1',
      future3label: '',
      future4: '#009ad1',
      future4label: '',
      future5: '#009ad1',
      future5label: '',
      future6: '#009ad1',
      future6label: '',
      future7: '#009ad1',
      future7label: '',
    },
    // start button color (homepage)
    startButton: '',
    // start button text color (homepage)
    startButtonText: '',
    // linebreaks thoughout the application should use this colours e.g. `<hr />` elements
    line: '',
    logo: false,
  },
  // application modules, false by default.
  // using redux state you can turn each one of the modules on or off using boolens
  // if a player is missing an applcation from its config then it will inherit the default
  // value from this object
  modules: {
    developmentOverview: {
      weight: 1,
      customIcon: false,
      title: 'Development Overview',
      enabled: true,
    },
    propertyOptionCenter: {
      weight: 2,
      customIcon: false,
      title: 'Property Option center',
      enabled: true,
    },
    individualPropertyTypeOverview: {
      weight: 3,
      customIcon: false,
      title: 'Individual Property Type Overview',
      enabled: true,
    },
    plots: {
      weight: 4,
      customIcon: false,
      title: 'Plots',
      enabled: true,
    },
    localInformation: {
      weight: 5,
      customIcon: false,
      title: 'Local Information',
      enabled: true,
    },
    transportationLinks: {
      weight: 6,
      customIcon: false,
      title: 'Transportation Links',
      enabled: true,
    },
    otherHousingDevelopments: {
      weight: 7,
      customIcon: false,
      title: 'Other Housing Developments',
      enabled: true,
    },
    promotionsAndSalesMethods: {
      weight: 8,
      customIcon: false,
      title: 'Promotions & Sales Methods',
      enabled: true,
    },
    designAdvice: {
      weight: 9,
      customIcon: false,
      title: 'Design Advice',
      enabled: true,
    },
    registerInterest: {
      weight: 10,
      customIcon: false,
      title: 'Register Interest',
      enabled: true,
    },
    contactUs: {
      weight: 11,
      customIcon: false,
      title: 'Contact Us',
      enabled: true,
    },
    wishList: {
      weight: 12,
      customIcon: false,
      title: 'Wishlist',
      enabled: true,
    },
    mortgageCalculator: {
      weight: 13,
      customIcon: false,
      title: 'Mortgage Calculator',
      enabled: true,
    },
  },
  // used for house types
  // each key is related to a card within property type overview
  // can be used elsewhere in the appication however there is currently no
  // other use than housetype view
  houseModules: {
    floorplan: {
      weight: 1,
      enabled: true,
    },
    options: {
      weight: 2,
      enabled: true,
    },
    gallery: {
      weight: 3,
      enabled: true,
    },
    flyover: {
      weight: 4,
      enabled: true,
    },
    location: {
      weight: 5,
      enabled: true,
    },
  },
};

export default propertiesDefaultState;
