import schema from '../schemas/_streetView';

const streetPinReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_STREET_PINS':
      return {
        ...state,
        streetPins: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export { streetPinReducer };
