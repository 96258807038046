import { Heading, Page } from '../../components';
import { Box, Typography } from '@mui/material';
import { Button, TextInput, Select, Price } from '../../components/FormV2/components';
import { useYupResolver } from '../../hooks';
import { useForm } from 'react-hook-form';
import { plotSchema } from './plotSchema';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import plotService from '../../lib/signtouch/plotsService';
import { setPlots } from '../../store/actions/plots';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { addError, addNotification } from '../../utils';
import { statusesAsOptions } from './constants';
 
export const EditPlotPage = () => {

  const properties = useSelector((state) => state.properties);
  const { map } = properties.branding;
  let statusTypesAsOptions = [];
  Object.keys(map)
    .filter(key => !key.endsWith("label"))
    .reduce((obj, key) => {
      obj = { label: map[key + "label"], value: key };
      statusTypesAsOptions.push(obj);
    }, {});
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resolver = useYupResolver(plotSchema);
  const { control, handleSubmit } = useForm({
    resolver,
  });

  const [saving, setSaving] = useState(false);

  const { developmentId, plotId } = useParams();
  const houseTypes = useSelector(state => state.houseTypes);
  const houseTypesAsOptions = houseTypes.map(houseType => ({
    label: houseType.name,
    value: houseType.id
  }));
  console.log("houseTypesAsOptions:", houseTypesAsOptions)

  const plot = useSelector(state => state.plots.find(plot => `${plot.id}` === plotId));

  console.log("plot:", plot)
  const onSubmit = async data => {
    setSaving(true);
    const pendingToast = addPending('updating plot');

    try {
      await plotService.updatePlot({
        ...plot,
        ...data
      });
      await dispatch(setPlots());
      removePending(pendingToast);
      addNotification('Successfully updated plot');
      setSaving(false);
      navigate(`/developments/${developmentId}/plots`)
    } catch (error) {
      removePending(pendingToast);
      addError('Unable to update plot');
      setSaving(false);
    }
  };

  return (
    <Page>
      <Heading label={`Create Plot`} />

      <Box
        sx={{
          maxWidth: '600px', p: 4, backgroundColor: 'white', borderRadius: 2
        }}
      >
        <Typography variant="h6" component="h2">
          Edit Plot
        </Typography>
        <Typography sx={{ mt: 1, mb: 2 }}>
          Edit a plot, you can plot it on the map later.
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box sx={{
              width: '50%', display: 'flex', flexDirection: 'column', gap: 1
            }}>
              <TextInput
                full
                name='number'
                label='PLOT NUMBER'
                control={control}
                defaultValue={plot.number}
              />
              <Price
                full
                name='price'
                label='PRICE'
                control={control}
                defaultValue={plot.price}
              />
              <Select
                full
                name='status'
                label='STATUS'
                control={control}
                options={statusTypesAsOptions}
                defaultValue={plot.status}
              />
              <Select
                full
                name='houseType'
                label='HOUSE TYPE'
                control={control}
                options={houseTypesAsOptions}
                defaultValue={plot.houseType}
              />
            </Box>
            <Button type='submit' disabled={saving}>{saving ? 'Saving...' : 'Save'}</Button>
          </Box>
        </form>
      </Box>
    </Page>
  );
};
