import schema from '../schemas/_registerInterest';

const integrationsReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_INTEGRATIONS':
      return {
        ...action.data,
      };
    case 'UPDATE_INTEGRATIONS':
      return {
        ...action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export { integrationsReducer };
