import React, {useRef } from 'react';
import { Html } from '@react-three/drei';

const FiberTooltip = ({ children, isVisible = false, text }) => {
  const ref = useRef();
  return (
    <group ref={ref}>
      {children}
        <Html position={[0, 0, 0]}>
          <div style={{
            display: !isVisible ? 'block' : 'none',
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            padding: '5px',
            borderRadius: '5px',
            maxWidth: '200px',
            whiteSpace: 'nowrap',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -270%)',
          }}>
            {text}
          </div>
        </Html>
    </group>
  );
};

export default FiberTooltip;
