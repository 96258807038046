import { Environment, Html, OrbitControls, Sphere, useGLTF, useProgress } from "@react-three/drei"
import { useFrame, useThree } from "@react-three/fiber"
import React, { useEffect, useRef, useState } from "react"
import * as THREE from 'three';
import './styles.scss'
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { sortCubeMapUrls } from "../../utils/sortCubemapUrl";
import FiberTooltip from "../FiberToolTip";
import { ArrowBack } from "@mui/icons-material";
import { set } from "lodash";

const PanoramaViewer = ({ parentPanoramas, setParentPanoramas, mode, setMode, savePin, deletePin, setClickedData, editor, setEditor, beingViewed, setBeingViewed, rerender}) => {
  // get params
  const { pinId, developmentId } = useParams();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1200px)' });
  const { currentDevelopment } = useSelector((state) => state.general);
  const mediaurl = process.env.NODE_ENV === 'development' ? 'https://signtouch-media.alisco-it.com' : currentDevelopment?.mediaUrl; // actual url
  const loadMedia = (type, files) => {
    const urls = files.map((face) => 
    {
      switch (type) {
        case 'panorama': 
          return `${mediaurl}/${face}`;
        case 'pin':
          return `${mediaurl}/pin_${pinId}_${face}.jpg`;
        default:
          return `${mediaurl}`
      }
    }
    );
    return sortCubeMapUrls(urls);
  }
  const [panoramas, setPanoramas] = useState(
    loadMedia('pin', ['px', 'nx', 'py', 'ny', 'pz', 'nz'])
  );
  const [houseView, setHouseView] = useState(null);
  const  [ getHouseView ] = useSelector((state) => state.houseView.filter((view) => view.id === houseView?.id));
  const { camera, scene, size, gl } = useThree();
  const raycaster = new THREE.Raycaster();
  const { active } = useProgress();
  const { environmentConfig } = useSelector((state) => state.environmentConfig);
  const { nodes, materials } = useGLTF(mediaurl+environmentConfig.entranceIcon)
  const sphereRef = useRef();
  const menuWidth = isSmallScreen ? 0 : (document.getElementsByClassName('side-bar')[0]?.offsetWidth ?? 57);
  const topBarHeight = document.getElementsByClassName('development-banner')[0].offsetHeight ?? 262;

  const handleClick = (event) => {
    if (editor) {
      if (!sphereRef.current) return;
      const mouse = new THREE.Vector2();
      console.log('menuWidth:', topBarHeight);
      mouse.x = (((event.clientX) / (size.width)) * 2 - 1) - (menuWidth * 2 / size.width);
      mouse.y = -((event.clientY) / (size.height)) * 2 + 1 + (topBarHeight * 2 / size.height);  
      
      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObjects(scene.children, true);

      if (intersects.length > 0) {
        const point = intersects[0].point;
        savePin({point});
      }
    }
  }

  const handleArrowClick = (files, data) => {
    setClickedData(data);
    if (mode === 'default' || mode === 'house-view') {
      console.log('mode:', mode, files);
      if (files.length === 0) return;
      // console.log('files:', files, panoramas);
      const urls = loadMedia('panorama', files);
      console.log('urls:', urls);
      setPanoramas(urls); // Update state with new panorama URLs
    } else if (mode === 'delete') {
      deletePin({...data});
    }
  };

  const backButton = () => {
    if (mode !== 'house-view') {
      navigate(`/developments/${developmentId}/street-view/configuration`)
    } else {
      setBeingViewed(null);
      setMode('default');
      setPanoramas(sortCubeMapUrls([
        `${mediaurl}/pin_${pinId}_px.jpg`,
        `${mediaurl}/pin_${pinId}_nx.jpg`,
        `${mediaurl}/pin_${pinId}_py.jpg`,
        `${mediaurl}/pin_${pinId}_ny.jpg`,
        `${mediaurl}/pin_${pinId}_pz.jpg`,
        `${mediaurl}/pin_${pinId}_nz.jpg`]
      ));
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const canvas = gl.domElement;
      const width = canvas.clientWidth;
      const height = canvas.clientHeight;
  
      if (canvas.width !== width || canvas.height !== height) {
        gl.setSize(width, height, false);
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        console.log('resized:', width, height);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial size
    return () => window.removeEventListener('resize', handleResize);
  }, [camera, gl]);

  useEffect(() => {
    console.log('parentPanoramas:', parentPanoramas, pinId);
    if (!parentPanoramas) {
      setBeingViewed(null);
      setMode('default');
      const files = loadMedia('pin', ['px', 'nx', 'py', 'ny', 'pz', 'nz']);
      setPanoramas(files);
    } else {
      const files = loadMedia('panorama', parentPanoramas.map((panorama) => panorama.url.replace('/', '')));
      setPanoramas(files);
    }
  }, [parentPanoramas]);

  useEffect(() => {
    if (getHouseView) {
      setHouseView(getHouseView);
    }
  }, [rerender]);
  return (
      <group onClick={handleClick}>
        <Environment blur={active ? 0.5 : 0} files={ panoramas } background />
        <Sphere ref={sphereRef} args={[500, 60,40]}  scale={[-1, 1, 1]}>
          <meshBasicMaterial visible={false} side={THREE.BackSide}/>
        </Sphere>
        
        { mode === 'house-view'
        ? 
          houseView.housePaths.length > 0 && houseView.housePaths.map((path, index) => (
            <React.Fragment key={index}>
              {beingViewed?.id === path?.optionId &&
                <group key={index} position={new THREE.Vector3(path.position.x, path.position.y, path.position.z)} scale={4} rotation={[-Math.PI/ 2, 0, 0]}>
                  <Arrow nodes={nodes} materials={materials} data={path} load={handleArrowClick} mode={mode} setMode={setMode} setHouseView={setHouseView} beingViewed={beingViewed} setBeingViewed={setBeingViewed}/>
                </group>
              }
            </React.Fragment>
          )) 
        :
          environmentConfig[`house_entry_links_${pinId}`] && environmentConfig[`house_entry_links_${pinId}`].map((link, index) => (
            <group key={index} position={new THREE.Vector3(link.position.x, link.position.y, link.position.z)} scale={4} rotation={[-Math.PI/ 2, 0, 0]}>
              <Arrow nodes={nodes} materials={materials} data={link} load={handleArrowClick} mode={mode} setMode={setMode} setHouseView={setHouseView} beingViewed={beingViewed} setBeingViewed={setBeingViewed} />
            </group>) 
          )
        }
        <Html fullscreen style={{ width: '100px', height: '100px' }}>
          <div style={{ margin: '10px', display: active ? 'none' : 'block' }}>
            <div className="buttonWithIcon">
              <ArrowBack style={{ cursor: 'pointer', fill: '#fff', fontSize: '2rem' }} onClick={()=> backButton()} />
            </div>
          </div>
        </Html> 
        <OrbitControls zoomSpeed={0} enablePan={false} enableZoom={false} enableDamping={false} rotateSpeed={-0.35} />
      </group>
  )
}

const Arrow = ({nodes, materials, data, load, mode, setMode, setHouseView, beingViewed, setBeingViewed}) => {
  
  const arrowRef = useRef();
  const houseView = useSelector((state) => state.houseView.filter((view) => view.id === data.house_view_id)[0]);
  const houseType = useSelector((state) => state.houseTypes.filter((type) => type.id === houseView?.houseTypeId)[0]);
  const handleClick = () => {
    if (mode === 'default') {
      if (houseView) {
        const firstFloor = houseView.floors[0];
        const firstAreaOnFloor = houseView.options.filter((option) => option.floorId === firstFloor.id)[0];
        setBeingViewed(firstAreaOnFloor);
        if (firstAreaOnFloor.panoramas) {
          const files = firstAreaOnFloor.panoramas.map((panorama) => panorama.url.replace('/', ''));
          console.log('files', files);
          load(files, {data, houseView, houseType});
          setHouseView(houseView);
          setMode('house-view');
        }
      } 
    } else if (mode === 'house-view') {
      if (houseView) {
        const [ getPanorama ] = houseView.options.filter((option) => option.id === data?.pinOptionId);
        if (getPanorama.panoramas) {
          const files = getPanorama.panoramas.map((panorama) => panorama.url.replace('/', ''));
          setBeingViewed(getPanorama);
          console.log('files', files);
          load(files, {data, houseView, houseType});
        }
      }
    } else if (mode === 'delete') {
      load([], data);
    }
  }
  useFrame(() => {
    // move arrow silightly up and down
  if (arrowRef.current)
    arrowRef.current.rotation.z += 0.02;
  });

  return (
    <>
    {nodes.MapPointer5_MapPointer5_0 && materials.MapPointer5 &&
      <FiberTooltip text={beingViewed ? houseView.options.filter((f)=> f.id === data.pinOptionId)[0]?.name : houseType?.name}>
        <group dispose={null} onClick={()=>handleClick()}>
          <ambientLight intensity={0.2} />
          <mesh ref={arrowRef} geometry={nodes.MapPointer5_MapPointer5_0.geometry} material={materials.MapPointer5} />
        </group>
      </FiberTooltip>
    }
    </>
  );
};

export default PanoramaViewer
