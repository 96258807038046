import { Button, Heading, Page, SubHeading } from '../../components';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addError, addNotification, beablooApi } from '../../utils';
import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { MultiFloorImageSection } from './MultiFloorImageSection';
import { MultiFloorFloorsSection } from './FloorsSection';
import { setMultiFloor } from '../../store/actions/multiFloor'
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { multiFloorService } from '../../lib/signtouch';


export const MultiFloorConfigurationPage = () => {
  const { developmentId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const multiFloor = useSelector(state => state.multiFloor);
  const [floors, setFloors] = useState(multiFloor.floors.sort((a, b) => a.order - b.order));
  const [multiFloorImage, setMultiFloorImage] = useState(null);
  
  const [saving, setSaving] = useState(false);


  const handleUpdateMultiFloor = async () => {
    const pendingToast = addPending('updating multi floor...');
    setSaving(true); 

    const payload = { floors };

    if (multiFloorImage) payload.developmentImage = multiFloorImage;

    try {
      await multiFloorService.updateMultiFloor(payload);
      addNotification('multi floor updated');
      dispatch(setMultiFloor(payload));
    } catch {
      throw new Error('unable to update multi floor')
    }

    setSaving(false);
    removePending(pendingToast);
  };

  const handleDevelopmentImageChange = values => {
    setMultiFloorImage(values.developmentImage);
  };

  const handleFloorsChange = values => {
    setFloors(values);
  };

  const handleCreateFloor = () => {
      navigate(`/developments/${developmentId}/multi-floor/floors/create`);
  };

  return (
    <Page>
      <div className='heading-with-button'>
        <Heading label={`Multi Floor Configuration`} />
        <Button label={'create floor'} onClick={handleCreateFloor}/>
      </div>
      <SubHeading>
        Here you can manage the floors and development overview of your site.
      </SubHeading>
      {}
        <Box sx={{ maxWidth: '1600px', width: '100%' }}>
          <Stack direction={'column'} alignItems='stretch' spacing={{ xs: 1, sm: 2 }}>
            <MultiFloorFloorsSection 
              onChange={handleFloorsChange}
            />  
            <Box flexGrow={1}>
              <MultiFloorImageSection
                onChange={handleDevelopmentImageChange}
              />
            </Box>
          </Stack>
        </Box>
      <Button
        label={saving ? 'saving...' : 'Update Multi Floor'}
        onClick={handleUpdateMultiFloor}
        disabled={saving}
      />
    </Page>
  );
};
