import './styles.scss';


const Video = ({ thumbnail, onDelete, index }) => {
  return (
    <div className='image'>
      <div className='cross' onClick={() => onDelete(index)} />
      <div   className='content'>
        <video
          src={thumbnail}
          controls
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    </div>
  );
};

export default Video;
