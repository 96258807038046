import { blobServices } from "../../lib"
import { ApiError } from "../../utils";

const getStreetPins = () => {
  return async (dispatch, getState) => {
    const fileName = 'ModelConfigrations1.json';
    const jsonFile = await blobServices.download(fileName)
    .then((res) => res.data)
    .catch((e) => {
      throw new ApiError('failed to get street pins', [e], 400);
    });
    console.log('jsonFile:', jsonFile);
    dispatch({
      type: 'SET_STREET_PINS',
      data: jsonFile.streetViewPins,
    });
  }
}

const getEnvironment = () => {
  return async (dispatch, getState) => {
    const fileName = 'EnviornmentConfig.json';
    const jsonFile = await blobServices.download(fileName)
    .then((res) => res.data)
    .catch((e) => {
      throw new ApiError('failed to get enviornment', [e], 400);
    });
    console.log('jsonFile:', jsonFile);
    dispatch({
      type: 'SET_ENVIRONMENT',
      data: jsonFile,
    });
  }
}

const updateEnvironment = (data) => {
  return async (dispatch, getState) => {
    dispatch({
    type: 'SET_ENVIRONMENT',
    data: data,
    });
  }
}

export {
  getStreetPins,
  getEnvironment,
  updateEnvironment,
}