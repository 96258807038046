import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { Floor, SortableFloor } from './Floor';
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Sortable } from './Sortable';
import { useDispatch, useSelector } from 'react-redux';
import { setFloorOrder } from '../../store/actions/multiFloor';
import { Stack } from '@mui/material';
import { useEffect } from 'react';

export const MultiFloorFloorsSection = ({ onChange, houseTypeId }) => {
  const dispatch = useDispatch();
  const multiFloor = useSelector(state => state.multiFloor);
  const houseView = useSelector(state => state.houseView.filter(house => house.houseTypeId === parseInt(houseTypeId)));
  const floors = multiFloor.floors.sort((a, b) => a.order - b.order);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = event => {
    const {active, over} = event;
    
    if (active.id !== over.id) {
      const ids = floors.map(floor => floor.id);
      const oldIndex = ids.indexOf(active.id);
      const newIndex = ids.indexOf(over.id);
      const newOrder = arrayMove(floors, oldIndex, newIndex);

      const newFloors = newOrder.map((floor, index) => {
        return {
          ...floor,
          order: index + 1
        }
      });

      dispatch(setFloorOrder(newFloors));
      onChange(newFloors);
    }
  }

  const handleDeleteFloor = id => {
    const newFloors = floors.filter(floor => floor.id !== id);
    dispatch(setFloorOrder(newFloors));
    onChange(newFloors);
  };
  useEffect(() => {
  }, [houseTypeId]);

  return (
    <Stack direction="column" alignItems="stretch" flexGrow={1}>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={floors} strategy={verticalListSortingStrategy}>
          {houseTypeId
          ? houseView.floors && houseView.floors.map((floor) => (
            <SortableFloor key={floor.id} id={floor.id} label={floor.name} onDelete={handleDeleteFloor} />
          ))
          : floors.map((floor) => (
            <SortableFloor key={floor.id} id={floor.id} label={floor.name} onDelete={handleDeleteFloor} />
          ))}
        </SortableContext>
      </DndContext>
    </Stack>
  );
};
