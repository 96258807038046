import React from 'react'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
import { DeleteIcon } from '../../../../components/Icons'
import './styles.scss'
import { Box } from '@mui/material'

export const OptionsCards = ({option, ...props}) => {
  return (
    <div key={option.id} className='options'>
      <p>{option.name}</p>
      <div className='option-icons'>
        <Box onClick={()=> props.handleEdit(option.id)} >
          <PencilSquareIcon />
        </Box>
        <Box onClick={()=> props.handleDelete(option.id)} >
          <DeleteIcon />
        </Box>
      </div>
    </div>
  )
}
