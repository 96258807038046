import { useState } from 'react';
import { Form, Heading, Page } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { addError, addNotification } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { houseViewService } from '../../lib/signtouch';
import { updateHouseView } from '../../store/actions/houseView';

export const CreateHouseViewFloorPage = () => {
  const [saving, setSaving] = useState(false);
  const { developmentId } = useParams();

  const navigate = useNavigate();
  const { houseTypeId } = useParams();
  const dispatch = useDispatch();
  const houseView = useSelector(state => state.houseView.filter(house => house.houseTypeId === parseInt(houseTypeId)))[0];

  const handleSubmit = async data => {
    if (!data.name || data.name.trim() === '') {
      addError('Floor name is required');
      return;
    }

    if (!data.image) {
      addError('Floor image is required');
      return;
    }

    const payload = {
      floors: [
        ...houseView.floors,
        {
          ...data,
          backgroundColor: data.backgroundColor ? data.backgroundColor : '#ffffff',
          order: houseView.floors.length + 1
        }
      ]
    }

    const pendingToast = addPending('Adding your floor...');
    setSaving(true);

    try {
      const response = await houseViewService.updateHouseView(payload, houseView.id);
      dispatch(updateHouseView(response));
  
      addNotification('Floor was created successfully')
      navigate(`/developments/${developmentId}/house-view/multi-floor/configuration/${houseTypeId}`);
      
    } catch {
      addError('unable to create floor');
    }

    setSaving(false);
    removePending(pendingToast);
  };

  return (
    <Page>
      <Heading label='create floor' />
      <Form 
        onSubmit={handleSubmit}
        canSubmit={!saving}
        inputs={[
          {
            name: 'name',
            type: 'text',
            label: 'name',
          },
          {
            name: 'image',
            label: 'Image',
            type: 'single-image',
          },
          {
            name: 'backgroundColor',
            label: 'Background Color',
            type: 'colour',
            initialValue: '#ffffff',
          }
        ]}
      />
    </Page>
  )
};