import { ColorPicker, toColor, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';

import { useEffect, useState, setState } from 'react';

import './style.scss';
import { useRef } from 'react';

const ColorPickerInputWithText = (props) => {
  const [color, setColor] = useColor();
  const [labelvalue, setLabelValue] = useState('');
  const [expand, setExpand] = useState(false);
  const [active, setActive] = useState(false);
  const [init, setInit] = useState(false);

  const [isTop, setTop] = useState(false);

  const colorPicker = useRef();

  useEffect(() => {
    if (
      props.initialValue &&
      typeof props.initialValue === 'string' &&
      props.initialValue.charAt(0) === '#'
    ) {
      setColor(toColor('hex', props.initialValue));
      setLabelValue(props.initialLabelValue);
    }
  }, []);

  useEffect(() => {
    if (props.openedModal !== props.name) {
      setExpand(false);
    }
  }, [props.openedModal]);

  useEffect(() => {
    setInit(true);
  }, [color]);

  const handleChange = (e) => {
    setColor(e);
    setActive(true);

    if (props.hasOwnProperty('onChange')) {
      props.onChange({ name: props.name, value: e.hex });
    }
  };

  const handleLabelChange = (e) => {
    setLabelValue(e.target.value);

    if (props.hasOwnProperty('onChange')) {
      props.onChange({ name: props.name+'label', value: e.target.value });
    }
  };

  const handleOpen = () => {
    setExpand(true);
    setActive(true);

    if (props.hasOwnProperty('notifyOpen')) {
      props.notifyOpen(props.name);
    }
  };

  const handleClose = () => {
    setActive(false);
  };

  return (
    <div className='form-input-select' ref={colorPicker}>
      {!props.hideLabel && 
      <div className='form-input-text'>
        <label>
        <input
          placeholder={props.label ? props.label : ''}
          onChange={handleLabelChange}
          value={labelvalue}
          />
          </label>
      </div>
      }
      <div onMouseDown={(e) => handleOpen()} className='color-container'>
        <div className='color-preview' style={{ background: color.hex }} />
        <div className='colourText'>
          <p>{color.hex}</p>
          <p className='small'>Click to change color.</p>
        </div>
      </div>
      {expand ? (
        <div
          onMouseEnter={(e) => setActive(true)}
          onMouseDown={(e) => setActive(true)}
          onMouseUp={(e) => setActive(true)}
          onMouseLeave={(e) => handleClose(e)}
          className={`color-float ${isTop ? 'top' : 'bottom'}`}
        >
          {init && (
            <ColorPicker
              width={256}
              height={178}
              color={color}
              onChange={handleChange}
              dark
              hideHSV
              hideRGB
            />
          )}
          <button
            onClick={(e) => setExpand(false)}
            className='button button-primary color-picker'
          >
            Close
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default ColorPickerInputWithText;
