import { MultiFloorMap, MultiFloorProvider } from '@signtouch/multi-floor-map/dist/cjs/components';
import { useBranding } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { SettingPinToast } from './SettingPinToast';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FloorOptionsList } from './Component';
import { houseViewService } from '../../lib/signtouch';
import { updateHouseView } from '../../store/actions/houseView';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import './styles.scss';

const Container = styled.div({
  height: '100%',
  width: '100%',
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
});


export const HouseViewFloorMap = () => {
  const { houseId } = useParams();
  const [branding] = useBranding();
  const houseView = useSelector(state => state.houseView.filter(hv => hv.id === parseInt(houseId)))[0];
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);
  const [activePin, setActivePin] = useState(null);
  const [currentFloor, setCurrentFloor] = useState(null);
  const [inOpreation, setInOperation] = useState(false);

  const handlePinDrop = async value => {
    setInOperation(true);
    const pending = addPending('Updating house view');
    setEditMode(false);
    setActivePin(null);
    // make payload
    const options = houseView.options.filter(option => option.id !== activePin.id);
    const payload = {
      ...activePin,
      floorId: value.floorId,
      coordinates: value.coordinates,
      placed: true,
    }
    const updatedOptions = [
      ...options,
      payload
    ];
    const updatedHouseView = {
      ...houseView,
      options: updatedOptions,
    }

    await houseViewService.updateHouseView(updatedHouseView, updatedHouseView.id).then((r) => {
      dispatch(updateHouseView(r));
      removePending(pending);
      setInOperation(false);
    }).catch((e) => {
      removePending(pending);
      setInOperation(false);
    });
  }

  const handleEditPin = pin => {
    setInOperation(true);
    setActivePin(pin);
    setEditMode(true);
  };

  const handleClosePinToast = () => {
    setInOperation(false);
    setEditMode(false);
    setActivePin(null);
  }
  
  const handleFloorChange = floor => {
    setCurrentFloor(floor);
  }

  return (
    <>
      <Container style={{ overflow: 'hidden' }}>
        <div className='map-container'>
          <MultiFloorProvider
            floors={houseView.floors}
            data={houseView.options}
            style={{ zIndex: 5 }}
            editor={editMode}>
            <MultiFloorMap 
              branding={branding}
              onFloorChange={handleFloorChange}
              onClick={handlePinDrop}
              />
          </MultiFloorProvider>
        </div>
        <FloorOptionsList
          houseView={houseView}
          currentFloor={currentFloor}
          handleEditPin={handleEditPin}/>
      </Container>
      {editMode && activePin && (
        <SettingPinToast
          pin={activePin}
          onCancel={handleClosePinToast}
        />
      )}
    </>
  )
}
