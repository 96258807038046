import React, { useEffect, useState } from 'react'
import './styles.scss'
import { Button, Heading, Page, SidebarSeperator, SubHeading } from '../../components'
import { Box, Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { mapHouseViewAndHouseType } from '../../utils/combineHouseTypeAndHouseView'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import {  Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { OptionsCards } from './components'
import { blobServices, houseViewService } from '../../lib/signtouch'
import { updateHouseView } from '../../store/actions/houseView'
import addPending from '../../utils/addPending'
import removePending from '../../utils/removePending'
import { addError } from '../../utils'

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export const FloorOptionsPage = () => {
  const query = useQuery();
  const { developmentId, houseId } = useParams();
  const houseView = useSelector((state) => state.houseView);
  const houseTypes = useSelector((state) => state.houseTypes);
  const { branding } = useSelector((state) => state.properties);
  const [selectedHouse, setSelectedHouse] = useState(houseId !== undefined ? houseView.filter((hv) => hv.id === parseInt(houseId))[0] : null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [combined, setCombined] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleHouseChange = (data) => {
    console.log(data)
    setSelectedFloor(null);
    if (selectedHouse && (selectedHouse.id === data.houseViewId)) {
      setSelectedHouse(null);
    } else {
      setSelectedHouse(data);
    }
  }

  const handleNavigate = () => {
    navigate(`/developments/${developmentId}/house-view/floor-options/${selectedHouse.id}/create-option/${selectedFloor.id}`)
  }

  const handleDelete = async (optionId) => {
    console.log(optionId)
    const pending = addPending('Deleting option');
    const updatedOptions = selectedHouse.options.filter((option) => option.id !== optionId);
    const panoramas = selectedHouse.options.filter((option) => option.id === optionId)[0].panoramas;
    console.log(panoramas.map((panorama) => panorama.url))
    await blobServices.deleteMultiple(panoramas.map((panorama) => panorama.url)).then(async ()=> {
      await houseViewService.updateHouseView({options: updatedOptions}, selectedHouse.houseViewId ? selectedHouse.houseViewId : selectedHouse.id)
      .then((r) => {
          dispatch(updateHouseView(r))
          setSelectedHouse({...selectedHouse, options: r.options});
          removePending(pending);
        }).catch((e) => {
            addError('Failed to delete option');
            removePending(pending);
          });
    })
  }

  const handleEdit = (optionId) => {
    navigate(`/developments/${developmentId}/house-view/floor-options/${selectedHouse.id}/edit-option/${selectedFloor.id}/${optionId}`)
  } 

  useEffect(() => {
    mapHouseViewAndHouseType(houseView, houseTypes).then((combined) => {
      setCombined(combined);
      setLoading(false);
    });
  }, [selectedHouse, setSelectedFloor, updateHouseView])

  // get floorId from query params
  useEffect(() => {
    const floorId = query.get('floorId');
    if (floorId) {
      setSelectedFloor(selectedHouse.floors.filter((floor) => floor.id === floorId)[0]);
    }
  }, [])

  return (
      <Grid container spacing={2}>
        <Grid item sm={9} md={8.5}>
          <Page>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Heading label='Rooms / Areas'/>
                <SubHeading>Select a floor to add rooms / areas</SubHeading>
              </Box>
              <Box>
                {selectedFloor && 
                  <Button label={'Create Room / Area'} onClick={handleNavigate}/>
                }
              </Box>
            </Box>
            <Box>
              {selectedFloor 
              ? 
              <p>
                {(selectedHouse.options && selectedHouse.options.length !== 0)
                  ? selectedHouse.options.map((option, index) => {
                    return (
                      <React.Fragment key={index}>
                      {option.floorId === selectedFloor.id &&
                        <OptionsCards key={index} option={option} handleDelete={handleDelete} handleEdit={handleEdit}/>
                      }
                      </React.Fragment>)}
                  )
                  : <p>No Options Created yet</p>
                }
              </p>
              : <p>select a floor from right pane to add options/ areas</p>}
            </Box>
          </Page>
        </Grid>
        <Grid item sm={3} className='action-pane'>
          <h1 style={{ marginBottom: '5px' }}>
            House Types / Floors
          </h1>
          <h4 style={{ marginTop: 0 }}>
            Select a floor to add rooms / areas.
          </h4>
          <SidebarSeperator styles={{width: '100%'}} />
          <div className="overflow-content">
            {loading 
              ? 'loading...'
              : combined.map((house, index) => (
                <React.Fragment key={index}> 
                  <div key={house.houseViewId} className={selectedHouse?.id === house.houseViewId  ? 'selected-dropdown' : '' } style={{ overflowY: 'auto' }}>
                    <div className='expandable' onClick={() => handleHouseChange(house)}>
                      <label>{house.name}</label>
                      {selectedHouse?.id === house.houseViewId 
                      ? <ChevronUpIcon className='expandable-icon' />
                      : <ChevronDownIcon className='expandable-icon' /> }
                    </div>
                    <div className={selectedHouse?.id === house.houseViewId  ? 'expandable-content show' : 'expandable-content hide'}>
                      {house.floors.length !== 0
                        ? house.floors.map((floor,index) => (
                            <div key={index}
                              onClick={() => setSelectedFloor(floor)}
                              className={selectedFloor?.id === floor.id  ? 'selected floor' : 'floor'}
                              style={{ cursor: 'pointer' }}
                              >
                              {floor.name}
                            </div>
                        ))
                        : <p className='remove-padding-and-margin'>No Floors Available <Link style={{ color: branding.primary }} to={`/developments/${developmentId}/house-view/multi-floor/configuration/${house.houseTypeId}`}>Create a Floor?</Link></p>
                      }
                    </div>
                  </div>
                  <SidebarSeperator styles={{width: '100%'}} />
                </React.Fragment>
              ))  
            }
          </div>
        </Grid>
      </Grid>
  )
}
