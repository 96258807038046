const generalSchema = {
  ready: false,
  loadError: false,
  currentDevelopment: {
    id: false,
    name: false,
    type: false,
    city: false,
    region: false,
    mapType: false,
    mapData: false,
    mediaUrl:''
  },
  currenltyLoading: '',
};

export default generalSchema;
