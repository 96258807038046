import { useState } from 'react';
import { Form, Heading, Page } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { addError, addNotification } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { setFloorOrder } from '../../store/actions/multiFloor';
import { houseViewService } from '../../lib/signtouch';
import { updateHouseView } from '../../store/actions/houseView';

export const EditHouseViewFloorPage = () => {
  const [saving, setSaving] = useState(false);
  const { developmentId, houseTypeId, floorId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const multiFloor = useSelector(state => state.houseView.filter(house => house.houseTypeId === parseInt(houseTypeId)))[0];

  const floor = multiFloor.floors.find(floor => floor.id === floorId);
  if (!floor) {
    navigate(`/developments/${developmentId}/house-view/configuration`);
    addError('Floor not found');
    return null;
  }

  const handleSubmit = async data => {
    const updatedFloor = {
      ...floor,
      ...data,
    };

    const updatedFloors = multiFloor.floors.map(floor => {
      if (floor.id === floorId) {
        return updatedFloor;
      }
      return floor;
    });

    const payload = {
      floors: updatedFloors
    };
    const pendingToast = addPending('Updating your floor...');
    setSaving(true);

    try {
      await houseViewService.updateHouseView(payload, multiFloor.id)
      .then((res) =>{
        dispatch(updateHouseView(res));
        addNotification('Floor was updated successfully')
        navigate(`/developments/${developmentId}/house-view/configuration`);
      });   
    } catch {
      addError('unable to update floor');
    }

    setSaving(false);
    removePending(pendingToast);
  };

  return (
    <Page>
      <Heading label='Edit floor' />
      <Form 
        onSubmit={handleSubmit}
        canSubmit={!saving}
        inputs={[
          {
            name: 'name',
            type: 'text',
            label: 'name',
            initialValue: floor.name,
          },
          {
            name: 'image',
            label: 'Image',
            type: 'single-image',
            initialValue: floor.image,
          },
          {
            name: 'backgroundColor',
            label: 'Background Color',
            type: 'colour',
            initialValue: floor.backgroundColor,
          }
        ]}
      />
    </Page>
  )
};