import { styled } from 'styled-components';
import { PlotGroup } from './PlotGroup';
import { useSelector } from 'react-redux';

const PlotListContainer = styled.div({
  backgroundColor: '#121212',
  height: '100%',
  width: '400px',
  zIndex: 10,
  overflowY: 'scroll',
});

export const PlotList = ({ plots, onPlotClicked }) => {
  const multiFloor = useSelector(state => state.multiFloor);
  const floors = multiFloor.floors;
  const plotsByFloor = plots.reduce((acc, plot) => {
    const floor = floors.find(floor => floor.id === plot?.floor);
    if (!plot.floor || !floor) {
      if (!acc['unassigned']) {
        acc['unassigned'] = [];
      }
      acc['unassigned'].push(plot);
      return acc;
    };

    if (!acc[floor.name]) {
      acc[floor.name] = [];
    }
    acc[floor.name].push(plot);
    return acc;
  }, {});

  const reorderedPlots = Object.fromEntries(
    Object.entries(plotsByFloor).sort(([keyA], [keyB]) => {
      if (keyA === 'unassigned') return -1;
      if (keyB === 'unassigned') return 1;
      return 0;
    })
  );

  return (
    <PlotListContainer>
      {reorderedPlots && Object.keys(reorderedPlots).map(floor => (
        <PlotGroup
          key={floor}
          label={floor}
          plots={reorderedPlots[floor]}
          onPlotClicked={onPlotClicked}
        />
      ))}
    </PlotListContainer>
  )
};
