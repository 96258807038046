import React, { useState } from 'react'
import { Button, Form, Heading, Page, SubHeading } from '../../../../components'
import './styles.scss'
import { houseViewService } from '../../../../lib/signtouch'
import { useNavigate, useParams } from 'react-router-dom'
import addPending from '../../../../utils/addPending'
import removePending from '../../../../utils/removePending'
import { useDispatch, useSelector } from 'react-redux'
import { updateHouseView } from '../../../../store/actions/houseView'
import { Typography } from '@mui/material'
import { addError } from '../../../../utils'
import { v4 as uuid } from 'uuid';

export const CreateOptions = () => {
  const { developmentId ,houseId, floorId } = useParams();
  const [saving, setSaving] = useState(false);
  const houseView = useSelector(state => state.houseView.filter(houseView => houseView.id === parseInt(houseId)))[0];
  const dispatch = useDispatch();
  const [optionData, setOptionData] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    const pending = addPending('Creating option');
    setSaving(true)
    let payload = {};
    let panoramas = [];
    if (!data.optionName || data.optionName === '') {
      addError('Option Name is required');
      setSaving(false);
      removePending(pending);
      return;
    }
    // if we have any of the panorama images then we need to have all of them
    if (data.px || data.py || data.pz || data.nx || data.ny || data.nz) { 
      // check if any of the panorama is not empty
      if (!data.px || !data.py || !data.pz || !data.nx || !data.ny || !data.nz) {
        addError('px, py, pz, nx, ny, nz are required');
        setSaving(false);
        removePending(pending);
        return;
      } else {
        const imageGuid = uuid();
        panoramas = [
          {
            name:`${imageGuid}_px`,
            image: data.px
          },
          {
            name:`${imageGuid}_py`,
            image: data.py
          },
          {
            name:`${imageGuid}_pz`,
            image: data.pz
          },
          {
            name:`${imageGuid}_nx`,
            image: data.nx
          },
          {
            name:`${imageGuid}_ny`,
            image: data.ny
          },
          {
            name:`${imageGuid}_nz`,
            image: data.nz
          }
        ]
      }
    }
    else {
      addError('px, py, pz, nx, ny, nz are required');
      setSaving(false);
      removePending(pending);
      return;
    }
    const newEntry = {
      name: data.optionName,
      defaultOption: data.defaultOption ? data.defaultOption : false,
      floorId: floorId,
      placed: false,
      panoramas: panoramas
    }
    let defaultOption = (houseView && houseView.options) && houseView?.options.some(option => option.floorId === floorId && option.defaultOption === true);
    if (newEntry.defaultOption && defaultOption) {
      addError('Cannot set this room/area as default because another room/area is already set as the default. If you want to make it enable first disable the default room/area.')
      setSaving(false)
      removePending(pending)
      return;
    }
    if (houseView.options !== undefined) {
      payload = {
        options: [...houseView.options, newEntry]
      }
    } else {
      payload = {
        options: [newEntry]
      }
    }
    await houseViewService.updateHouseView(payload, parseInt(houseId)).then((r) => {
      dispatch(updateHouseView(r));
      setSaving(false)
      navigate(`/developments/${developmentId}/house-view/floor-options/${houseId}?floorId=${floorId}`)
    }).catch((e) => {
      setSaving(false)
      addError('Failed to create option')
    })
    removePending(pending);
  }

  return (
    <Page style={{ position: 'relative' }}>
      <Heading label='Create Room / Area'/>
      <SubHeading>Fill the form to create room / area on the selected floor.</SubHeading>
      <div style={{ paddingBottom: '16px' }}>
        <Form
          inputs={[
            {
              type: 'text',
              label: 'Room / Area Name',
              name: 'optionName',
            },
            { 
              type: 'switch',
              label: 'Make Default',
              name: 'defaultOption',
            },

            {
              text: 'Panoramas',
              type: 'label',
              label: 'Panoramas',
            },
            {
              type: 'single-image',
              label: 'PX',
              name: 'px',
            },
            {
              type: 'single-image',
              label: 'PY',
              name: 'py',
            },
            {
              type: 'single-image',
              label: 'PZ',
              name: 'pz',
            },
            {
              type: 'single-image',
              label: 'NX',
              name: 'nx',
            },
            {
              type: 'single-image',
              label: 'NY',
              name: 'ny',
            },
            {
              type: 'single-image',
              label: 'NZ',
              name: 'nz',
            }]}
            onChange={(data) => setOptionData(data)}
            onSubmit={() => handleSubmit({
              ...optionData,
            })}
            canSubmit={!saving}
            />
      </div>
    </Page>
  )
}
