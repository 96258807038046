import schema from '../schemas/_streetView';

const environmentReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_ENVIRONMENT':
      return {
        ...state,
        environmentConfig: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export { environmentReducer };
