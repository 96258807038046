export const data = [
  {
    id: 1,
    name: 'Ground',
    image: {
      id: 1234,
      url: 'https://via.placeholder.com/2000x2000',
    },
    order: 1,
  },
  {
    id: 2,
    name: '1',
    image: {
      id: 1235,
      url: 'https://via.placeholder.com/600x600',
    },
    order: 2,
  },
  {
    id: 3,
    name: '2',
    image: {
      id: 1236,
      url: 'https://via.placeholder.com/700x700',
    },
    order: 3,
  },
];

export const dummyImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADMUExURf///8vLywsLCwAAAJycnO7u7gcHBwUFBWZmZri4uLm5uV5eXqenp4eHh1xcXGVlZX19fb+/v4GBgeXl5bu7u+bm5n9/f09PT/n5+U5OTkVFRUJCQnR0dF9fX0BAQG1tbTc3N/r6+unp6QEBAZOTk/z8/Ofn5xoaGtbW1vLy8gwMDLa2tv7+/kpKSgICApWVlVJSUllZWY6OjkREROHh4bOzs/39/fHx8dXV1ZKSkvv7+29vb3Jycl1dXb6+vrq6uoiIiAgICJ2dnczMzGlmVpQAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGcSURBVHhe7dhpT8JAGATg4gCCgILghXiiovW+LxSP//+f3E0mEbSJ9Fq0zvNp9k3YzIc2Da8nIiIiIiIiIiIif0JuChFM5fjz2PK8MbQ8L4ipUMR0qTxDFaDKaNSAGqNRBWYZZ8pz0ygWeEU8daDBaMwDTUajBbQYjQVgkdFoAHXGeJaAZUZj/ALLwApjPG2AyRq/gAe0meL5DQXmP60CHUZjDagwGh1gndFKsEBU2SnQjCSdhzAEFch+gY3NLaZgaRfYBro7uzwESbvAnn3Te/s8BXBSANg44PkbRwXgHx5x8oWrAsDx0PdxiLsCODnlbITDAsAZh8OcFjjncJjDAt0Lzka4K3B5xdEoVwX86xtOvnBUoHTL8zdOCvTueAqQdgH7Mbp/4CFI2gW8xwl/jn+kAlkqsBBJggWiyk6BxU/rQJ/ReAKeGI2++XvOaKXzEP7HDcnEl1R14JnRGL/Ac1JrOruonCsPyC4qGY0XoMZo2EUl48AuKl+TWVR6b3ypQnvjBbHlfN4Yiv/On4uIiIiIiIiIiIjIb+Z5H7mFO3jtSHkxAAAAAElFTkSuQmCC'