import { styled } from 'styled-components';
import { useBranding } from '../../hooks';

const Container = styled.div(({ backgroundColor }) => ({
  position: 'absolute',
  bottom: '10px',
  left: '10px',
  backgroundColor,
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  padding: '10px',
  borderRadius: '5px',
  alignItems: 'center',
  zIndex: 100,
}));

const Label = styled.p({
  color: '#fff',
  fontSize: '16px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  margin: 0,
});

const CancelButton = styled.button(({ backgroundColor }) => ({
  backgroundColor,
  fontSize: '16px',
  fontWeight: 'bold',
  color: '#fff',
  textTransform: 'uppercase',
  border: 'none',
  borderRadius: '5px',
  padding: '10px',
  cursor: 'pointer',
}));

export const SettingPinToast = ({ pin, onCancel }) => {
  const [branding] = useBranding();

  return (
    <Container backgroundColor={branding.primary}>
      <Label>Plotting {pin.name}</Label>
      <CancelButton
        backgroundColor={branding.secondary}
        onClick={onCancel}
      >
        Cancel
      </CancelButton>
    </Container>
  )
};
