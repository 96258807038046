import React, { useState } from 'react'


const SwitchInput = ({ name, label, initialValue, onChange,disabled }) => {
  const [checked, setChecked] =  useState(initialValue);

  const handleInputChange = (e) => {
    const value = e.target.checked;
    setChecked(value);
    onChange({ name, value });
  };

  return (
    <div key={name} className='form-switch'>
      <label>{label}</label>
      <div className='switch-container'>
        <input
          type='checkbox'
          id={`${name}-switch`}
          name={name}
          checked={checked}
          onChange={handleInputChange}
          disabled={disabled}
        />
        <label htmlFor={`${name}-switch`} className={'switch'}>
          {checked ? 'Yes' : 'No'}
        </label>
        {disabled && <p className='switch-disabled-message'>{`Cannot set this room/area as default because another room/area is already set as the default. If you want to make it enable first disable the default room/area.`}</p>}
      </div>
    </div>
  );
};


export default SwitchInput;