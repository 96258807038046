import React, { useEffect, useState } from 'react'
import { Button, Form, Heading, Page, SubHeading } from '../../../../components'
import './styles.scss'
import { blobServices, houseViewService } from '../../../../lib/signtouch'
import { useNavigate, useParams } from 'react-router-dom'
import addPending from '../../../../utils/addPending'
import removePending from '../../../../utils/removePending'
import { useDispatch, useSelector } from 'react-redux'
import { updateHouseView } from '../../../../store/actions/houseView'
import { addError } from '../../../../utils'
import loader  from '../../../../assets/pulse.gif'
import { v4 as uuid } from 'uuid';

export const EditFloorOptions = () => {
  const { developmentId, houseId, optionId, floorId } = useParams();
  const [saving, setSaving] = useState(false);
  const houseView = useSelector(state => state.houseView.filter(houseView => houseView.id === parseInt(houseId)))[0];
  const dispatch = useDispatch();
  const [optionData, setOptionData] = useState(() => houseView.options.filter(option => option.id === optionId)[0]);
  const [panoramaImages, setPanoramaImages] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // Load images asynchronously and store them in the state
    const loadImages = async () => {
      const images = {};
      const panoramaNames = ['px', 'py', 'pz', 'nx', 'ny', 'nz'];

      for (let name of panoramaNames) {
        const panorama = optionData.panoramas.find(image => image.name.includes(name));
        if (panorama) {
          const image = await blobServices.download(panorama.url.replace('/', ''));
          images[name] = image.image;
        }
      }

      setPanoramaImages(images);
    };

    loadImages();
  }, [optionData]);


  const checkDefaultOption = (houseView) => {
    let defaultOption = (houseView && houseView.options) && houseView?.options.some(option => option.floorId === floorId && option.defaultOption === true);
    console.log(defaultOption)
    return defaultOption 
  };

  const handleSubmit = async (data) => {
    const pending = addPending('Creating option');
    setSaving(true);
    let panoramas = [];

    if (optionData.name === null || data.optionName === '') {
      addError('Option Name is required');
      setSaving(false);
      removePending(pending);
      return;
    }

      if (optionData.panoramas.length !== 6 || (data.px === null || data.py === null || data.pz === null || data.nx === null || data.ny === null || data.nz === null)) {
        addError('px, py, pz, nx, ny, nz are required');
        setSaving(false);
        removePending(pending);
        return;
      } else {
        panoramas = [
          {
            id: optionData.panoramas.find(image => image.name.includes('px')).id,
            name: optionData.panoramas.find(image => image.name.includes('px')).name.split('.')[0],
            image: data.px || panoramaImages.px
          },
          {
            id: optionData.panoramas.find(image => image.name.includes('py')).id,
            name: optionData.panoramas.find(image => image.name.includes('py')).name.split('.')[0],
            image: data.py || panoramaImages.py
          },
          {
            id: optionData.panoramas.find(image => image.name.includes('pz')).id,
            name: optionData.panoramas.find(image => image.name.includes('pz')).name.split('.')[0],
            image: data.pz || panoramaImages.pz
          },
          {
            id: optionData.panoramas.find(image => image.name.includes('nx')).id,
            name: optionData.panoramas.find(image => image.name.includes('nx')).name.split('.')[0],
            image: data.nx || panoramaImages.nx
          },
          {
            id: optionData.panoramas.find(image => image.name.includes('ny')).id,
            name: optionData.panoramas.find(image => image.name.includes('ny')).name.split('.')[0],
            image: data.ny || panoramaImages.ny
          },
          {
            id: optionData.panoramas.find(image => image.name.includes('nz')).id,
            name: optionData.panoramas.find(image => image.name.includes('nz')).name.split('.')[0],
            image: data.nz || panoramaImages.nz
          }
        ];
      }
    
    

    const modifiedEntry = {
      name: data.optionName || optionData.name,
      defaultOption: data.defaultOption ?? optionData.defaultOption,
      floorId: optionData.floorId,
      placed: optionData.placed,
      panoramas: panoramas,
      id: optionData.id
    };

    const findHouseViewToReplace = houseView.options.filter(option => option.id !== optionId);
    const payload = {
      options: [...findHouseViewToReplace, modifiedEntry]
    };

    await houseViewService.updateHouseView(payload, parseInt(houseId))
      .then((r) => {
        dispatch(updateHouseView(r));
        setSaving(false);
        navigate(`/developments/${developmentId}/house-view/floor-options/${houseId}?floorId=${optionData.floorId}`);
      })
      .catch((e) => {
        setSaving(false);
      });

    removePending(pending);
  };

  return (
    <Page style={{ position: 'relative' }}>
      <Heading label='Edit Room / Area' />
      <SubHeading>Fill the form to create room / area on the selected floor.</SubHeading>
      <div style={{ paddingBottom: '16px' }}>
        <Form
          inputs={[
            { type: 'text', label: 'Room / Area Name', name: 'optionName', initialValue: optionData.name },
            { type: 'switch', label: 'Make Default', name: 'defaultOption', 
              initialValue: optionData.defaultOption, 
              disabled: checkDefaultOption(houseView) && !optionData.defaultOption},
            { type: 'label', label: 'Panoramas', name: 'panoramas' },
            { type: 'single-image', label: 'PX', name: 'px', initialValue: panoramaImages.px || loader },
            { type: 'single-image', label: 'PY', name: 'py', initialValue: panoramaImages.py || loader },
            { type: 'single-image', label: 'PZ', name: 'pz', initialValue: panoramaImages.pz || loader },
            { type: 'single-image', label: 'NX', name: 'nx', initialValue: panoramaImages.nx || loader },
            { type: 'single-image', label: 'NY', name: 'ny', initialValue: panoramaImages.ny || loader },
            { type: 'single-image', label: 'NZ', name: 'nz', initialValue: panoramaImages.nz || loader }
          ]}
          onSubmit={(data) => handleSubmit(data)}
          canSubmit={!saving}
        />
      </div>
    </Page>
  );
}
