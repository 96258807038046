import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useState } from 'react';
import { useEffect } from 'react';
import { Form } from '../../components';
import { SingleImage } from '../../components/Form/components';

export const Module = (props) => {
  // Creates an instance of sortable
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.itemKey });

  const [expandeded, setExpand] = useState(false);

  const setExpanded = (val) => {
    if (props.hasOwnProperty('canExpand') && props.canExpand) {
      setExpand(!expandeded);
    }
  };

  // Keeps track of local state, it's kept in the parent too but needs to be here so it can track updates
  const [enabled, setEnabled] = useState(props.enabled);
  const [title, setTitle] = useState(props.title);
  const [icon, setIconState] = useState(props.icon);

  // Set the icon
  const setIcon = (icon) => {
    if (icon.hasOwnProperty('value')) {
      setIconState(icon.value);
      return;
    }

    if (icon.hasOwnProperty('url')) {
      setIconState(icon);
      return;
    }

    setIconState(false);
  };

  // Checks for toggle of disabling and tells the parent.
  useEffect(() => {
    if (props.hasOwnProperty('disableModule')) {
      props.disableModule(props.id, enabled);
    }
    // eslint-disable-next-line
  }, [enabled]);

  useEffect(() => {
    if (props.hasOwnProperty('setTitle')) {
      props.setTitle(props.id, title);
    }
  }, [title]);

  useEffect(() => {
    if (props.hasOwnProperty('setIcon')) {
      props.setIcon(props.id, icon);
    }
  }, [icon]);

  // Style function for the dragging - needs some tweaking.
  const style = {
    // THis stops the odd scaling when dragging an expanded module
    transform: transform
      ? ` translate3d(${transform.x}px, ${transform.y}px, 0)`
      : ` translate3d(0px, 0px, 0)`,
    transition,
    maxHeight: !expandeded ? '3.5em' : '22em',
  };

  return (
    <div
      className={'module'}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className={'collapse'} onClick={(event) => setExpanded(!expandeded)}>
        {props.canExpand && (
          <div
            className={
              expandeded ? 'collapse-container' : 'collapse-container collapsed'
            }
          >
            <span />
            <span />
          </div>
        )}
      </div>
      <div className={'modules-header'}>
        <div>
          <input
            type={'checkbox'}
            name={'moduleState'}
            id={`input-${props.itemKey}`}
            checked={enabled}
          />
          <label className={'switch'} onClick={(e) => setEnabled(!enabled)}>
            Enabled
          </label>
        </div>
        <div className={'flex-grow'} onClick={(e) => setExpanded(!expandeded)}>
          {/*This needs a function to make the ID the module title - can't use title as that's user configurable*/}
          <h3>{idToName(props.id)}</h3>
        </div>
      </div>

      {props.canExpand && (
        <div className={'settings'}>
          <label>
            Display Name
            <input
              type={'text'}
              name={'moduleName'}
              placeholder={'Module Name'}
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </label>
          <div className={'icon'}>
            <SingleImage onChange={setIcon} initialValue={props.customIcon} />
          </div>
        </div>
      )}
    </div>
  );
};

function idToName(id) {
  switch (id) {
    case 'developmentOverview':
      return 'Development Overview';
    case 'individualPropertyTypeOverview':
      return 'Property Types Overview';
    case 'plots':
      return 'Plots';
    case 'localInformation':
      return 'Local Information';
    case 'transportationLinks':
      return 'Transport Links';
    case 'otherHousingDevelopments':
      return 'Other Developments';
    case 'promotionsAndSalesMethods':
      return 'Promotions & Sales Methods';
    case 'propertyOptionCenter':
      return 'Property Option Center';
    case 'designAdvice':
      return 'Design Advice';
    case 'registerInterest':
      return 'Register Interest';
    case 'contactUs':
      return 'Contact Us';
    case 'wishList':
      return 'Wishlist';
    case 'floorplan':
      return 'Floorplan';
    case 'options':
      return 'Property Option';
    case 'gallery':
      return 'Gallery';
    case 'flyover':
      return 'Flyover';
    case 'location':
      return 'Development Location';
    case 'mortgageCalculator': 
      return 'Mortgage Calculator'
    default:
      return 'Error: ' + id;
  }
}
