import { useEffect, useState } from 'react';
import '../../../../styles/_forms.scss';

 
import { parseMediaObject } from '../../../../utils';
import { v4 as uuid } from 'uuid';
import { isEqual } from 'lodash';
import Image from '../Image';
import Video from '../Video';

const MultiMedia = ({ name, label, onChange, initialValue = [] }) => {
  const [media, setMedia] = useState(initialValue);

  const handleDeleteMedia = (id) => {
    setMedia((state) => {
      return state.filter((item) => item.id !== id);
    });
  };

  const handleChange = () => {
    if (onChange)
      onChange({
        name,
        value: media.map((item) => {
          if (item.isBase64) {
            return item.url;
          }
          return item;
        }),
       
      });
  };

  const handleFile = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    const fileType = file.type.startsWith('image') ? 'image' : 'video';
    reader.readAsDataURL(file);
    reader.onload = () => {
      setMedia((state) => {
        return [...state, { id: uuid(), url: reader.result, isBase64: true, type: fileType }];
      });
    };
  };

  useEffect(() => {
    if (isEqual(initialValue, media)) {
      return;
    }
    handleChange();
  }, [media]);

  useEffect(() => {
    // check if we have any media objects
    if (
      initialValue &&
      initialValue.length > 0 &&
      initialValue[0].hasOwnProperty('url')
    ) {
      setMedia(initialValue);
    }
  }, []);

  return (
    <>
      <div className='form-multimedia'>
        <label>
          {label}
          <input
            type='file'
            accept='image/*, video/*'
            onChange={handleFile}
          />
        </label>
      </div>
      <div className='images'>
        {media.map((item, index) => {
          return item.type === 'image' ? (
            <Image
              key={`multi-media-${index}`}
              index={index}
              thumbnail={parseMediaObject(item)}
              onDelete={() => handleDeleteMedia(item.id)}
            />
          ) :item.type === 'video'? (
            <Video
              key={`multi-media-${index}`}
              thumbnail={parseMediaObject(item)}
              index={index}
              videoUrl={parseMediaObject(item)}
              onDelete={() => handleDeleteMedia(item.id)}
            />
          ):null
        })}
      </div>
    </>
  );
};

export default MultiMedia;
