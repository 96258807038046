import React, { Suspense, useEffect, useState } from 'react'
import { Button, SidebarSeperator } from '../../components'
import { mapHouseViewAndHouseType } from '../../utils/combineHouseTypeAndHouseView';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteIcon } from '../../components/Icons';
import { Box, Grid, Switch } from '@mui/material';
import PanoramaViewer from '../../components/PanoramaViewer';
import { Canvas } from '@react-three/fiber';
import './styles.scss';
import { useMediaQuery } from 'react-responsive';
import { blobServices, houseViewService } from '../../lib';
import { useParams } from 'react-router-dom';
import { addError } from '../../utils';
import { Html } from '@react-three/drei';
import { updateEnvironment } from '../../store/actions/streetView';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { v4 as uuid } from 'uuid';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { GpsNotFixed, Visibility } from '@mui/icons-material';
import { updateHouseView } from '../../store/actions/houseView';

const StreetViewMap = () => {
  const { pinId } = useParams();
  const houseView = useSelector((state) => state.houseView);
  const houseTypes = useSelector((state) => state.houseTypes);
  const [rerender, setRerender] = useState(false);
  const { environmentConfig } = useSelector((state) => state.environmentConfig);
  const { branding } = useSelector((state) => state.properties);
  const [loading, setLoading] = useState(true);
  const [combinedData, setCombinedData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [follow, setFollow] = useState({x: 0, y: 0});
  const [clickedData, setClickedData] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [beingViewed, setBeingViewed] = useState(null);
  const [panoramas, setPanoramas] = useState(null);
  const [mode, setMode] = useState('default');
  const [editor, setEditor] = useState(false);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1200px)' });
  const menuWidth = isSmallScreen ? 0 :(document.getElementsByClassName('side-bar')[0]?.offsetWidth ?? 57);
  const topBarHeight = document.getElementsByClassName('development-banner')[0]?.offsetHeight ?? 262;
  const dispatch = useDispatch();

  const savePin = async (data) => {
    const pending = addPending('Saving...');
    setEditor(false);
    if (mode === 'default') {
      if (!selectedOption) {
        addError('Please select a house type');
        return
      };
      const fileName = 'EnviornmentConfig.json';
      const payload = {
        id: uuid(),
        position: data.point,
        house_type_id: selectedOption.houseTypeId,
        house_view_id: selectedOption.houseViewId,
      }
      console.log('payload:', environmentConfig);
      let response = environmentConfig;
      if (response[`house_entry_links_${pinId}`]) {
        response[`house_entry_links_${pinId}`] = 
        [...response[`house_entry_links_${pinId}`], payload];
      } else {
        response = {
          ...response,
          [`house_entry_links_${pinId}`]: [payload]
        };
      }
      await blobServices.uploadSingle(fileName, response).then(() => {
        dispatch(updateEnvironment(response));
        removePending(pending);
      }).catch((error) => {
        addError('Error saving pin');
        removePending(pending);
      });
    } else if (mode === 'house-view') {
      if (!clickedData) {
        addError('no option selected');
        return
      };
      console.log('clickedData:', clickedData);
      const payload = {
        id: uuid(),
        position: data.point,
        house_type_id: clickedData?.houseType?.id,
        house_view_id: clickedData?.houseView?.id ,
        optionId: beingViewed?.id,
        pinOptionId: selectedOption?.id
      }
      console.log('option:', selectedOption);
      const [selectHouseView] = houseView.filter(view => view.id === clickedData?.houseView?.id);
      const mergePayload = {
        housePaths: [
          ...selectHouseView.housePaths,
          payload
        ]
      };
      console.log('mergePayload:', mergePayload);
      await houseViewService.updateHouseView(mergePayload, clickedData?.houseView?.id)
      .then((res) => {
        console.log('res:', res);
        dispatch(updateHouseView(res));
        removePending(pending);
        setRerender(!rerender);
      })
      .catch((error) => {
        addError('Error saving pin');
        removePending(pending);
      });
    };
  }

  const deletePin = async (data) => {
    const pending = addPending('Deleting...');
    let response = environmentConfig;
    console.log(response[`house_entry_links_${pinId}`], data);
    if (response[`house_entry_links_${pinId}`]) {
      response[`house_entry_links_${pinId}`] = response[`house_entry_links_${pinId}`].filter(pin => pin.id !== data.id);
    }
    console.log('response:', response);
    await blobServices.uploadSingle('EnviornmentConfig.json', response).then(() => {
      dispatch(updateEnvironment(response));
      removePending(pending);
    }).catch((error) => {
      addError('Error deleting pin');
      removePending(pending);
    });
  }
  // for combining house view and house type
  useEffect(() => {
    mapHouseViewAndHouseType(houseView, houseTypes).then((combined) => {
      setCombinedData(combined);
      setLoading(false);
    });
  }, [])
  // for follow div to follow mouse pointer
  useEffect(() => {
    const handleMouseMove = (e) => {
      setFollow({x: (e.clientX - 6) - menuWidth , y: (e.clientY - 6) - topBarHeight});
    }
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    }
  }, [isSmallScreen, menuWidth, topBarHeight]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={9}>
        <Box className={'position-relative'}>
          <Canvas className="main-content-sizing">
            <Suspense fallback={
              <Html fullscreen>
                <div style={{ display:'flex', justifyContent:'center', alignItems: 'center', width: '100%', height:'100vh' }}>
                  loading..
                </div>
              </Html>
              }> 
                <PanoramaViewer parentPanoramas={panoramas} setParentPanoramas={setPanoramas} mode={mode} setMode={setMode}
                  savePin={savePin} deletePin={deletePin} setClickedData={setClickedData} editor={editor} setEditor={setEditor}
                  beingViewed={beingViewed} setBeingViewed={setBeingViewed} rerender={rerender}
                />
            </Suspense>
          </Canvas>
          {editor && 
            <div className={'cancel-action'} style={{ backgroundColor: branding?.primary}}>
              <div>
                <p>Placing {selectedOption?.name}</p>
                <Button label={'Canel'} onClick={()=> setEditor(false)}/>
              </div>
            </div>
          }
        </Box>
        {editor && <div className='follow-div-street' style={{ left: `${follow.x}px`, top: `${follow.y}px` }}/>}
      </Grid>
      <Grid item sm={3} className='action-pane'>
        <div style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center' }}>
          <h1 style={{ marginBottom: '5px' }}>
            House Types
          </h1>
          {mode !== 'house-view' &&
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>delete mode
              <Switch color='error' onChange={(e)=> setMode(e.target.checked ? 'delete' : 'default')}/>
            </div>
          }
        </div>
        <h4 style={{ marginTop: 0 }}>
          Select house type to configure street view
        </h4>
        <SidebarSeperator styles={{ width: '100%' }} />
        <div className='overflow-content-map'>
          {(combinedData && mode === 'default') && combinedData.map((option, index) => (
            <React.Fragment key={index}>
              <div className={ 'expandable-content show'  }>
                <div
                  className={`floor ${selectedOption && selectedOption.houseTypeId === option.houseTypeId ? 'selected' : ''}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (mode === 'delete') {
                      addError('Please exit delete mode to select house type');
                      return;
                    }
                    setEditor(true);
                    setSelectedOption(option);
                  }}>
                    <p>{option.name ? option.name : 'Error House Type'}</p>
                </div>
              </div>
              <SidebarSeperator styles={{ width: '100%' }} />
            </React.Fragment>
          ))}
          {(mode === 'house-view' && clickedData) && 
            clickedData.houseView.floors.map((option, index) => (
              <React.Fragment key={index}>
                <div className={ `expandable-content ${selectedFloor?.id === option.id ? 'selected' : ''}` }>
                  <div
                    className={`floor}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (selectedFloor?.id === option.id) {
                        setSelectedFloor(null);
                        return;
                      }
                      setSelectedFloor(option);
                    }}>
                      <p style={{ display: 'flex', justifyContent:'space-between' }}>
                        <div>
                          {option.name}
                        </div>
                        {selectedFloor?.id === option.id 
                        ? <ChevronUpIcon className='expandable-icon' />
                        : <ChevronDownIcon className='expandable-icon' /> }
                      </p>
                  </div>
                  <div style={{ display: selectedFloor?.id === option.id ?  'block' : 'none', marginLeft: '1rem', fontWeight: 'normal' }}>
                    {clickedData.houseView.options && 
                      clickedData.houseView.options.map((option, index) => (
                        <React.Fragment key={index}>
                          {selectedFloor?.id === option.floorId &&
                            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <p>{option.name}</p>
                              <div>
                                {beingViewed?.id !== option.id && 
                                  <Visibility style={{ fontSize: '1rem' ,marginRight: '10px', cursor: 'pointer'}}
                                    onClick={() => {
                                      if (option.panoramas.length !== 6) {
                                        addError('Panorama files are missing');
                                        return;
                                      }
                                      setPanoramas(option.panoramas);
                                      setBeingViewed(option);
                                    }}
                                  />
                                }
                                <GpsNotFixed style={{ fontSize: '1rem', cursor: 'pointer'}}
                                  onClick={() => { setEditor(true); setSelectedOption(option) }}
                                />
                              </div>
                            </div>}
                        </React.Fragment>)
                      )
                    }
                  </div>
                </div>
                <SidebarSeperator styles={{ width: '100%' }} />
              </React.Fragment>))
            }
        </div>
      </Grid>
    </Grid>
  )
}

export default StreetViewMap