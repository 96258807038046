import { Heading } from '../../components';
import { Form } from '../../components';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { beablooApi } from '../../utils';
import { addNotification } from '../../utils';
import { addError } from '../../utils';
import { updatePromotion } from '../../store/actions/promotions-and-sales';
import { SignTouchPreview } from '../../components';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import PreviewPromotionsAndSalesMethod from '../../components/SignTouchPreview/pages/PreviewPromotionsAndSalesMethod';
import './style.scss';

const EditPromotionsAndSalesMethods = (props) => {
  const dispatch = useDispatch();
  const { promotionId, developmentId } = useParams();
  const { currentDevelopment } = useSelector((state) => state.general);

  const promotion = useSelector((state) => state.promotions).find(
    (promo) => parseInt(promo.id) === parseInt(promotionId),
  );
const [mediaType, setMediaType] = useState(promotion.mediaType);

  const [formData, setFormData] = useState({
    name: promotion.name,
    description: promotion.description,
    multimedia: promotion.multimedia,
    thumbnail: promotion.thumbnail,
    mediaType: mediaType
  });

  const [saving, setSaving] = useState(false);

  const handleFormSubmit = async () => {
    // tell the page we are saving
    setSaving(true);

    const pendingId = addPending('Updating Promotion');

    await beablooApi({
      method: 'PUT',
      route: `/developments/${developmentId}/promotions/${promotionId}/update`,
      payload: formData,
    })
      .then((r) => {
        // request complete no longer saving
        setSaving(false);
        // remove notification
        removePending(pendingId);
        if (!r.success) {
          throw new Error('unable to create promotion');
        }
        return r.data;
      })
      .then((r) =>
        dispatch(updatePromotion({ ...r, belongsTo: currentDevelopment.id })),
      )
      .then(() => addNotification('Updated Promotion'))
      .catch((e) => {
        addError(e.message);
      });
  };

  const handleFormChange = (data) => {
    const { name, multiMedia, thumbnail, description } = data;   

    if (name) {
      setFormData((state) => {
        return {
          ...state,
          name: name,
        };
      });
    }

     if (multiMedia) {
      if (typeof data.coverMedia == 'string') {
        if (data.coverMedia.includes('image')) {
          setMediaType('image');
        } else if (data.coverMedia.includes('video')) {
          setMediaType('video');
        }
      }
      setFormData((state) => {
        return {
          ...state,
          multimedia: multiMedia,
        };
      });
    }

    if (thumbnail) {
      setFormData((state) => {
        return {
          ...state,
          thumbnail: thumbnail,
        };
      });
    }

    if (description) {
      setFormData((state) => {
        return {
          ...state,
          description: description,
        };
      });
    }
  };

  return (
    <div className={'page-padding'}>
      <Heading label={`Edit ${promotion.name}`} />

      <div className='promotions-and-sales-methods'>
        <div className={'form-content'}>
          <Form
            inputs={[
              {
                type: 'text',
                label: 'name',
                name: 'name',
                initialValue: promotion.name,
              },
              {
                type: 'single-image',
                label: 'Thumbnail',
                name: 'thumbnail',
                initialValue: promotion.thumbnail,
              },
              {
                type:"multi-media",
                label:"Upload images/videos",
                name:"multiMedia",
                initialValue: promotion.multimedia,
                mediaType: mediaType
              },
              {
                type: 'textarea',
                label: 'description',
                name: 'description',
                initialValue: promotion.description,
              },
            ]}
            onChange={handleFormChange}
            onSubmit={handleFormSubmit}
            canSubmit={!saving}
          />
        </div>
        <div
          className={'preview'}
          style={{ position: 'fixed', right: '10vw', top: '8vh' }}
        >
          <SignTouchPreview>
            <PreviewPromotionsAndSalesMethod {...formData} />
          </SignTouchPreview>
        </div>
      </div>
    </div>
  );
};

export default EditPromotionsAndSalesMethods;
