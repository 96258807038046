import React, { useEffect, useState } from 'react'
import { Heading, Page } from '../../components'
import { Box } from '@mui/material'
import { blobServices } from '../../lib'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const StreetViewConfiguration = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const currentDevelopment = useSelector(
    (state) => state.general.currentDevelopment,
  );
  const { streetPins } = useSelector(
    (state) => state.streetPins
  );

  console.log(streetPins)

  return (
    <Page>
      <Box sx={{ maxWidth: '100vw', width: '100%' }}>
        <div className='heading' style={{ marginTop: '12px' }} >
          <Heading label='Street View Internal Configuration' />
        </div>
      </Box>

      {loading ?
      <div style={{
        fontSize: '14px',
      }}>Loading...</div> :
      <table cellSpacing={0}>
        <thead>
          <tr>
            <th width='auto' 
            // onClick={(e) => toggleSort('number')}
            >
              <div className='table-sort-container'>
                <p>Sr.No</p>
              
              </div>
            </th>
            <th width='auto'>Pin Number</th>

          </tr>
        </thead>
        <tbody>
          {streetPins &&
          streetPins.map((pin,index) => {
            return (
              <tr key={Math.random().toString(16)} >
                <td>{index+1}</td>
                <td 
                  style={{cursor: 'pointer'}} 
                  onClick={() => {navigate(`/developments/${currentDevelopment.id}/street-view/configuration/${pin.id}`)}}
                >
                  Pin Number {pin.id}
                </td>     
              </tr>
            );
          })}
        </tbody>
      </table>}
    </Page>
  )
}

export default StreetViewConfiguration