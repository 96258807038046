import { Form, Saving, Heading } from '../../components';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { beablooApi } from '../../utils';
import { useState } from 'react';
import { addError } from '../../utils';
import { addNotification } from '../../utils';
import { SignTouchPreview } from '../../components';
import { PreviewWelcome } from '../../components/SignTouchPreview/pages';
import './styles.scss';
import { setWelcome } from '../../store/actions/welcome';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';

const EditWelcomePage = () => {
  const { developmentId } = useParams();

  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState({});
  const welcome = useSelector((state) => state.welcome);

  // const socket = useSocket();
  const dispatch = useDispatch();

  const handleChange = (data) => {
    setData(data);

    if (data.video) {
      setData((state) => {
        return {
          ...state,
          video: data.video,
        };
      });
    }

    if (data.description) {
      setData((state) => {
        return {
          ...state,
          description: data.description,
        };
      });
    }

    if (data.rightText) {
      setData((state) => {
        return {
          ...state,
          rightText: data.rightText,
        };
      });
    }
  };

  const handleSubmit = async (data) => {
    // Start the user animation for saving
    setSaving(true);

    let pendingId = addPending('Saving...');

    const payload = {
      description: data.description,
      rightText: data.rightText,
    };

    if (data.video) {
      payload.video = data.video;
    }

    await beablooApi({
      method: 'PUT',
      route: `/developments/${developmentId}/pages/welcome/update`,
      payload: payload,
    }).then((r) => {
      removePending(pendingId);
      // if success is not true or doesn't exist, then try and handle the error.
      if (!r.success) {
        // Stop the saving animation
        setSaving(false);
        // Add the error message to a toast for the user.
        addError(r.message);
        // Put the errors in state so we can deal with them somehow.
        setErrors(r.errors);
        // Stop execution because there's nothing left for us to do here.
        return;
      }

      // Stop the saving animation
      setSaving(false);
      // Tell the global state that welcome has been updated.
      dispatch(setWelcome(r.data)); // add the plot to state
      // Give the user a toast to tell them it's been updated.
      addNotification('Welcome page has been updated.');
    });
  };

  return (
    <div className='welcome-page page-padding'>
      <div className='form-container'>
        <Heading label={`Edit Welcome Page`} />

        <Form
          errors={errors}
          inputs={[
            {
              name: 'description',
              type: 'textarea',
              label: 'description',
              initialValue: welcome.description,
              bgType: 'section',
            },
            {
              name: 'rightText',
              type: 'textarea',
              label: 'Short Text',
              initialValue: welcome.rightText,
              bgType: 'section',
            },
            {
              name: 'video',
              type: 'single-image',
              label: 'Video',
              initialValue: welcome.video,
              isVideo: true,
            },
          ]}
          onSubmit={handleSubmit}
          onChange={handleChange}
          canSubmit={!saving}
        />
      </div>
      <div style={{ position: 'fixed', right: '10vw', top: '10%' }}>
        <SignTouchPreview>
          <PreviewWelcome
            description={
              data.description ? data.description : welcome.description
            }
            rightText={data.shortText ? data.shortText : welcome.rightText}
            video={data.video ? data.video : welcome.video}
          />
        </SignTouchPreview>
      </div>
    </div>
  );
};

export default EditWelcomePage;
