import { MultiFloorMap, MultiFloorProvider } from '@signtouch/multi-floor-map/dist/cjs/components';
import { useBranding } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';
import { PlotList } from './components';
import { useState } from 'react';
import { SettingPlotToast } from './SettingPlotToast';
import { plotsService } from '../../lib/signtouch';
import { addError, addNotification } from '../../utils';
import removePending from '../../utils/removePending';
import { updatePlot } from '../../store/actions/plots';
import addPending from '../../utils/addPending';

const Container = styled.div({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
});

const MapContainer = styled.div({
  width: 'calc(100vw - 655px)',
  height: '100%'
});

export const MultiFloorMapPage = () => {
  const [branding] = useBranding();
  const plots = useSelector(state => state.plots);
  const { floors } = useSelector(state => state.multiFloor);
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);
  const [activePlot, setActivePlot] = useState(null);
  const handlePlotDrop = async value => {
    const pendingNotification = addPending('updating plot...');
    try {
      const updatedPlot = {
        ...activePlot,
        floor: value.floorId,
        coordinates: value.coordinates,
      }
  
      setEditMode(false);
      setActivePlot(null);
      
      const data = await plotsService.updatePlot(updatedPlot);
      dispatch(updatePlot(data));

      removePending(pendingNotification);
      addNotification('plot updated');

    } catch {
      removePending(pendingNotification);
      addError('failed to update plot');
    }
  }

  const handleEditPlot = plot => {
    setActivePlot(plot);
    setEditMode(true);
  };

  const handleClosePlotToast = () => {
    setEditMode(false);
    setActivePlot(null);
  }

  return (
    <>
      <Container>
        <MapContainer>
          <MultiFloorProvider
            floors={floors}
            plots={plots}
            style={{ zIndex: 5 }}
            editor={editMode}
          >
            <MultiFloorMap
              branding={branding}
              onClick={handlePlotDrop}
            />
          </MultiFloorProvider>
        </MapContainer>
        <PlotList
          plots={plots}
          onPlotClicked={handleEditPlot}
        />
      </Container>
      {editMode && activePlot && (
        <SettingPlotToast
          plot={activePlot}
          onCancel={handleClosePlotToast}
        />
      )}
    </>
  )
}
