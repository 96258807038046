import React, { useEffect, useState } from 'react'
import { Heading, Page } from '../../components'
import { DropDown } from '../../components/Form/components'
import { metabaseService } from '../../lib'

export const AnalyticsPage = () => {

  const [options, setOptions] = useState([]);
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const getOptions = async() => {
    return await metabaseService.getDashboardsByUsername({userName: JSON.parse(localStorage.getItem('signtouch-user')).username.toLowerCase()});
  }
  const getDashboard = async(dashboardId) => {
    await metabaseService.getDashboardByDashboardId(dashboardId).then((res) => {
      setDashboardUrl(res.iframeUrl);
    }).catch((e) => {
      console.log(e);
    });
  }

  useEffect(() => {
    getOptions().then((res) => {
      setOptions(()=>
        res.map(({dashboardId, name})=>({
          label: name,
          value: (dashboardId),
        }))
      )
    })
  }, [])
  return (
   <>
    <Page>
      <div className='heading-with-button'>
        <Heading label='Analytics'/>
        <DropDown options={options} onChange={(e) => getDashboard(parseInt(e.value))}></DropDown>
      </div>
      {dashboardUrl ?
        <iframe
          src={dashboardUrl}
          width={'100%'}
          height={600}
          allowTransparency
          frameBorder={0}
        />
        : <div>
          <p>Select a dashboard from DropDown to view</p>
        </div>
      }
    </Page>
   </>
  )
}
