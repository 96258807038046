import './styles.scss';

const Button = ({
  label,
  color,
  onClick = () => {},
  disabled = false
}) => {
    const disabledStyle = disabled ? { backgroundColor: 'gray', cursor: 'default' } : {};
    return (
    <button
      onClick={onClick}
      className='shared-button'
      style={color ? { backgroundColor: color , ...disabledStyle } : disabledStyle }
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
