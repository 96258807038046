import { useEffect, useRef, useState } from 'react';
import { parseMediaObject } from '../../../../utils';
import '../../../../styles/_forms.scss';
import Toggle from '../../../Toggle';

const SingleImage = ({ onChange, ...props }) => {
  const [state, setState] = useState([]);
  const [isVideo, setIsVideo] = useState(() => (props.isVideo || props.mediaType === "video") ? true : false);
  // if we have an initial value then set it
  const inputRef = useRef(null);
  useEffect(() => {
    if (props.initialValue) {
      setState([props.initialValue]);
    }
  }, [props.initialValue]);

  const handleSwitch = (e) => {
    setIsVideo(e);
    removeImage(); 
  }

  const handleFile = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      setState([reader.result]);
      if (onChange) {
        onChange({
          name: props.name,
          value: reader.result,
        });
      }
    };
  };

  const removeImage = (e) => {
    setState([]);
    inputRef.current.value = '';
  };

  useEffect(() => {
    if (onChange) {
      state.length > 0
        ? onChange({
            name: props.name,
            value: state[0],
          })
        : onChange({
            name: props.name,
            value: null,
          });
    }
  }, [state]);

  return (
    <>
      <div
        className={
          props.error
            ? `form-single-image error ${isVideo ? ' video' : ''}`
            : `form-single-image ${isVideo ? ' video' : ''}`
        }
      >
        {props.error ? 'An image is required.' : ''}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p className='media-label'>
            {props.switch ? props.label + (isVideo ? 'video' : 'image') : props.label}
          </p>
          {props.switch == true &&
            <Toggle
              initialValue={isVideo }
              onChange={handleSwitch}/>          
          }
        </div>
        <label>
          <input
            type='file'
            onInput={handleFile}
            ref={inputRef}
            accept={isVideo ? '.mp4' : '.png,.jpg,.jpeg'}
          />
        </label>
      </div>
      <div className='images'>
        {!isVideo && (
          <>
            {state.map((image, index) => {
              return (
                <Image
                  thumbnail={parseMediaObject(image)}
                  removeImage={() => removeImage(image)}
                  key={`${image.id}-i`}
                />
              );
            })}
          </>
        )}
        {isVideo &&
          <>
            {state.map((image, index) => {
              return (
                  <Image
                  thumbnail={parseMediaObject(
                    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADMUExURf///8vLywsLCwAAAJycnO7u7gcHBwUFBWZmZri4uLm5uV5eXqenp4eHh1xcXGVlZX19fb+/v4GBgeXl5bu7u+bm5n9/f09PT/n5+U5OTkVFRUJCQnR0dF9fX0BAQG1tbTc3N/r6+unp6QEBAZOTk/z8/Ofn5xoaGtbW1vLy8gwMDLa2tv7+/kpKSgICApWVlVJSUllZWY6OjkREROHh4bOzs/39/fHx8dXV1ZKSkvv7+29vb3Jycl1dXb6+vrq6uoiIiAgICJ2dnczMzGlmVpQAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGcSURBVHhe7dhpT8JAGATg4gCCgILghXiiovW+LxSP//+f3E0mEbSJ9Fq0zvNp9k3YzIc2Da8nIiIiIiIiIiIif0JuChFM5fjz2PK8MbQ8L4ipUMR0qTxDFaDKaNSAGqNRBWYZZ8pz0ygWeEU8daDBaMwDTUajBbQYjQVgkdFoAHXGeJaAZUZj/ALLwApjPG2AyRq/gAe0meL5DQXmP60CHUZjDagwGh1gndFKsEBU2SnQjCSdhzAEFch+gY3NLaZgaRfYBro7uzwESbvAnn3Te/s8BXBSANg44PkbRwXgHx5x8oWrAsDx0PdxiLsCODnlbITDAsAZh8OcFjjncJjDAt0Lzka4K3B5xdEoVwX86xtOvnBUoHTL8zdOCvTueAqQdgH7Mbp/4CFI2gW8xwl/jn+kAlkqsBBJggWiyk6BxU/rQJ/ReAKeGI2++XvOaKXzEP7HDcnEl1R14JnRGL/Ac1JrOruonCsPyC4qGY0XoMZo2EUl48AuKl+TWVR6b3ypQnvjBbHlfN4Yiv/On4uIiIiIiIiIiIjIb+Z5H7mFO3jtSHkxAAAAAElFTkSuQmCC',
                  )}
                  removeImage={() => removeImage(image.id)}
                  key={`${image.id}-i`}
                  />
                );
            })}
          </>
          }
      </div>
    </>
  );
};

const Image = (props) => {
  return (
    <div className='image'>
      {/* <div className='loading' /> */}
      <div className='cross' onClick={props.removeImage} />
      <div
        className='content'
        style={{
          backgroundImage: `url(${props.thumbnail})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      />
    </div>
  );
};

export default SingleImage;
