import { useEffect } from 'react';
import { useState } from 'react';
import './style.scss';

const Toggle = ({ initialValue, onChange, key, overrideState }) => {
  const [enabled, setEnabled] = useState(!!initialValue);

  useEffect(() => {
    if (typeof overrideState === 'boolean') {
      setEnabled(overrideState);
    }
  }, [overrideState]);

  useEffect(() => {
    if (onChange) {
      onChange(enabled);
    }
    // eslint-disable-next-line
  }, [enabled]);

  return (
    <div className={'input-toggle'}>
      <input
        readOnly
        type={'checkbox'}
        name={'moduleState'}
        checked={enabled}
      />
      <p className={'switch media-label'} onClick={(e) => setEnabled(!enabled)}>
        Enabled
      </p>
    </div>
  );
};

export default Toggle;
